import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, Card, Table } from 'react-bootstrap'

import ReactMarkdown from 'react-markdown'

import MainLayout from '../../components/main-layout'
import Seo from '../../components/seo'
import HeaderBlock from '../../components/header-block'

import { animate } from '../../styles/common.module.css'


const CommissionsPage = ({ data }) => {

    return (
        <MainLayout pageTitle="Commissions">

            <Seo title="Commissions" description="Info regarding ai03 Design Studio project/work commissions" />

            <Container>
                <HeaderBlock header="Commissions">
                    <ReactMarkdown children={data.strapiCommissions.pagecontents} />
                </HeaderBlock>

                <Card className={`shadow rounded-top overflow-hidden mb-4 ${animate}`}>
                    <Table responsive="md" striped className={`m-0 rounded-top`}>
                        <thead className={`table-dark rounded-top`}>
                            <tr className={`table-dark rounded-top`}>
                                <th scope="col">Commission Type</th>
                                <th scope="col">Description</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.strapiCommissions.commissiontype.map(field => (
                                    <tr key={field.id}>
                                        <td>{field.type}</td>
                                        <td>{field.description}</td>
                                        <td>{field.status}</td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </Table>
                </Card>

            </Container>


        </MainLayout>
    )
}

export const query = graphql`
query {
    strapiCommissions {
      pagecontents
      commissiontype {
        id
        type
        description
        status
      }
    }
  }
`

export default CommissionsPage